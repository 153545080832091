import { createContext } from 'react';

import { ApiState } from '@shared/containers/hooks/api/types';
import { Group } from '@shared/types';
import { ParsedQuery } from '@shared/utils/route';

import {
  TAbsenceProps,
  TAvailabilityProps,
  TPunchProps,
  TShiftModifyTimeOptions,
  TShiftMoveOptions,
  TShiftProps,
} from '@ManagerPortal/containers/Schedule/Main/SchedulerActionsProvider/types';
import {
  ApiStateWithIndexedData,
  ScheduleRow,
} from '@ManagerPortal/containers/Schedule/Main/types';
import {
  ILanguageContext,
  ManagerPortalUser,
  UserSettings,
} from '@ManagerPortal/types';
import { MealBreakSettings } from '@ManagerPortal/types/settings/mealBreaks';

import { GroupSettings } from './types';

export const LanguageContext = createContext<ILanguageContext>({
  language: 'en',
  dictionary: {},
  defaultDictionary: {},
});

export const UserContext = createContext<{
  user: ManagerPortalUser;
  receiveUser: () => void;
}>({
  user: {} as ManagerPortalUser,
  receiveUser: () => {},
});

export const UserSettingsContext = createContext<UserSettings | null>(null);

export const GroupSettingsContext = createContext<GroupSettings>({});

export const MuteSettingsContext = createContext(null);

export const MealBreaksContext =
  createContext<ApiState<MealBreakSettings> | null>(null);

export const AccountContext = createContext<{
  currentAccountId?: number;
  currentGroupId?: number;
  groups?: { [id: string]: Group };
  mainUnitGroupId?: number;
}>({});

export const AccountSettingsContext = createContext({});

export const ClipboardModeSubTypes = {
  DurationStart: 'duration-start',
  DurationEnd: 'duration-end',
  Copy: 'copy',
  Move: 'move',
} as const;

export type ClipboardModeSubType =
  (typeof ClipboardModeSubTypes)[keyof typeof ClipboardModeSubTypes];

export const ClipboardModeViaTypes = {
  Drag: 'drag',
  Panel: 'pick',
} as const;

export type ClipboardModeViaType =
  (typeof ClipboardModeViaTypes)[keyof typeof ClipboardModeViaTypes];

export type TClipboardModeType = {
  via: ClipboardModeViaType;
  sub?: ClipboardModeSubType;
};

export type TClipboardType =
  | {
      type: string;
      item: TShiftProps;
      mode: TClipboardModeType;
    }
  | Record<string, never>;

type TScheduleRowsContextData = {
  scheduleRows: {
    scheduleData: ScheduleRow[];
  };
  // TODO: Properly type these when transitioning
  // data setting in ScheduleRowsProvider functions to TS
  punchesMetadata: unknown;
  absencesMetadata: unknown;
};

export type TScheduleRowsContextType = TScheduleRowsContextData & {
  setSortedScheduleItems: (data: TScheduleRowsContextData) => void;
};

type TSchedulerDisplayStateContext = {
  isUnassignedRowVisible: boolean;
  toggleIsUnassignedRowVisible: () => void;
};

export const ClipboardContext = createContext<{
  clipboard: TClipboardType;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
  clear: () => void;
  setShift: (item: TShiftProps, mode?: TClipboardModeType) => void;
  setShiftById: (shiftId: number, mode?: TClipboardModeType) => void;
}>({
  clipboard: {},
  isLoading: false,
  setLoading: () => {},
  clear: () => {},
  setShift: () => {},
  setShiftById: () => {},
});

type TDestination = { employeeId: number; beginDay?: number; date: string };

export const BaseScheduleActionsContext = createContext<{
  onShiftCopy: (source: TShiftProps, destination: TDestination) => void;
  onShiftMove: (source: TShiftProps, destination: TDestination) => void;
  onShiftDelete: (shift: { id: number }) => void;
}>({
  onShiftCopy: () => {},
  onShiftMove: () => {},
  onShiftDelete: () => {},
});

// TODO - fix any
interface IGroupData {
  lockedDate: ApiState<any>;
  timeParameters: ApiState<any>;
  skills: ApiStateWithIndexedData<any>;
  absenceSchedules: ApiState<any>;
  sections: ApiStateWithIndexedData<any>;
  shiftTypes: ApiStateWithIndexedData<any>;
  costCentres: ApiStateWithIndexedData<any>;
  staffCategories: ApiStateWithIndexedData<any>;
  absenceReasons: ApiStateWithIndexedData<any>;
  projects: ApiStateWithIndexedData<any>;
  timeTrackers: ApiState<any>;
}

export const GroupDataContext = createContext<
  IGroupData | Record<string, never>
>({});

export const ScheduleRowsContext = createContext<TScheduleRowsContextType>({
  scheduleRows: { scheduleData: [] },
  punchesMetadata: {},
  absencesMetadata: {},
  setSortedScheduleItems: () => {},
});

type TScheduleActionsContext = {
  onShiftCopy: (sourceShift: TShiftProps, options: TShiftMoveOptions) => void;
  onShiftTimeCopy: (
    sourceShift: TShiftProps,
    options: TShiftModifyTimeOptions,
  ) => void;
  onShiftMove: (sourceShift: TShiftProps, options: TShiftMoveOptions) => void;
  onShiftTimeMove: (
    sourceShift: TShiftProps,
    options: TShiftModifyTimeOptions,
  ) => void;
  onShiftDelete: (shift: TShiftProps) => void;
  onAddPunchFromShift: () => void;
  onAbsenceDelete: (absence: TAbsenceProps) => void;
  onPunchDelete: (punch: TPunchProps) => void;
  onAvailabilityDelete: (availability: TAvailabilityProps) => void;
};

export const ScheduleActionsContext = createContext<TScheduleActionsContext>({
  onShiftCopy: () => {},
  onShiftTimeCopy: () => {},
  onShiftMove: () => {},
  onShiftTimeMove: () => {},
  onShiftDelete: () => {},
  onAddPunchFromShift: () => {},
  onAbsenceDelete: () => {},
  onPunchDelete: () => {},
  onAvailabilityDelete: () => {},
});

export const SchedulerDisplayStateContext =
  createContext<TSchedulerDisplayStateContext>({
    isUnassignedRowVisible: true,
    toggleIsUnassignedRowVisible: () => {},
  });

export const MessagesContext = createContext({
  onToggle: () => {},
  onClose: () => {},
  open: false,
  openNew: false,
  onOpenNew: () => {},
  onCloseNew: () => {},
  count: null,
});

type TSalaryWarningsContext = ApiState<{
  shiftWarnings: Record<number, any>;
  punchWarnings: object;
  absenceWarnings: never[];
  absenceWarningsObj: object;
}>;

export const SalaryWarningsContext = createContext<
  TSalaryWarningsContext | Record<string, never>
>({});

export const TimecardTableContext = createContext({});

export interface ILocationContextValue {
  isBaseSchedule: boolean;
  isDashboard: boolean;
  isPeople: boolean;
  isSchedule: boolean;
  isTransferToPayroll: boolean;
  isGroupSettings: boolean;
  url: string;
  query: ParsedQuery<string>;
}

export const LocationContext = createContext<null | ILocationContextValue>(
  null,
);

export const BaseScheduleNotificationContext = createContext({});

// todo - flesh this out more as we convert the schedule to TS
type TEventTrackingContextProps = { dayRange: number; timeframe: string };

export const EventTrackingContext = createContext<
  TEventTrackingContextProps | Record<string, never>
>({});

export const AppFeedbackModalContext = createContext({
  onOpenModal: () => {},
});

export const ForecastOverviewListContext = createContext({});
