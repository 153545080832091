import React, { Component, forwardRef, LegacyRef, useState } from 'react';

import classNames from 'classnames';
import t from 'prop-types';
import {
  ModifiersShape,
  SingleDatePicker,
  SingleDatePickerShape,
} from 'react-dates';

import useTranslate from '@shared/components/hooks/useTranslate';
import ArrowLeft from '@shared/components/Icons/ArrowLeft';
import ArrowRight from '@shared/components/Icons/ArrowRight';
import CalendarIcon from '@shared/components/Icons/CalendarIcon';
import LegacyDiv from '@shared/components/LegacyDiv';
import {
  dayOfMonth,
  format,
  isAfter,
  isBefore,
  month,
  monthsList,
  newDate,
  week,
  year,
} from '@shared/utils/time';
import { DATE_FORMAT, LOCALE_DATE_FORMAT } from '@shared/utils/time/constants';
import { DateTime, TDateFormatMap } from '@shared/utils/time/types';

import { newDatePickerFeatureToggle } from '@ManagerPortal/featureToggles';

import { Select } from '../DropdownInputs/Select';
import { withFormControl } from '../Form';
import Label from '../Label';
import {
  ReactDayPickerSingle as DatePickerNew,
  FormDatePicker as FormDatePickerNew,
  ReactDayPickerSingleWithFormControlProps,
} from './ReactDayPicker/ReactDayPickerSingle';

import s from './index.module.scss';

const years = (start: number, futureYearsRange: number) =>
  Array.from(Array(year() - start + 1 + futureYearsRange).keys())
    .map((i) => i + start)
    .reverse();

const renderDayWithWeekNumber = (day: DateTime, set: ModifiersShape) => {
  const dayNumber = dayOfMonth(day);
  const showWeekNumber = set.has('first-day-of-week') || dayNumber === 1;

  return (
    <LegacyDiv className={s.innerDayCell}>
      {showWeekNumber && (
        <LegacyDiv className={s.weekNumber}>{week(day)}</LegacyDiv>
      )}
      {dayNumber}
    </LegacyDiv>
  );
};

const yearOptions = years(1940, 9).map((value) => ({
  label: value.toString(),
  value,
}));

const isOutsideRange = (
  day: DateTime,
  minDate?: DateTime | string | number,
  maxDate?: DateTime | string | number,
) => {
  if (!!minDate && isBefore(day, minDate, 'day')) return true;
  return !!maxDate && isAfter(day, maxDate, 'day');
};

interface DatePickerProps {
  label?: string;
  info?: string;
  error?: string;
  numberOfMonths?: number;
  placeholder?: string;
  required?: boolean | string;
  id?: string;
  onChange: (update: string | null) => void;
  value?: string | DateTime;
  dateFormat?: string;
  displayFormat?: keyof TDateFormatMap;
  isClearable?: boolean;
  // disables dates earlier then specified
  minAvailableDate?: DateTime | string | number;
  // disables dates later then specified
  maxAvailableDate?: DateTime | string | number;
  className?: never;
  onBlur?: never;
  readOnly?: boolean;
  name?: never;
  'data-test-id': string;
  openDirection?: 'up' | 'down';
  anchorDirection?: 'left' | 'right';
}

const DatePicker = forwardRef(
  (
    {
      label = '',
      info,
      error,
      numberOfMonths = 1,
      placeholder,
      required,
      id = crypto.randomUUID(),
      onChange,
      value,
      // Should we even support non standard formats?
      dateFormat = DATE_FORMAT,
      displayFormat = LOCALE_DATE_FORMAT,
      isClearable,
      minAvailableDate: min,
      maxAvailableDate: max,
      className: _className,
      onBlur: _onBlur,
      name: _name, // We cant use name with react-dates component
      readOnly,
      'data-test-id': dataTestId,
      ...props
    }: DatePickerProps,
    ref: LegacyRef<Component<SingleDatePickerShape, unknown, unknown>>,
  ) => {
    const [pickerFocused, setPickerFocused] = useState(false);

    const translate = useTranslate();

    const monthOptions = monthsList().map((monthLabel, monthValue) => ({
      label: translate(monthLabel),
      value: monthValue,
    }));

    return (
      <LegacyDiv
        className={classNames(s.datePickerWrap, readOnly && s.readOnly)}
        data-test-id={dataTestId}>
        {label && <Label htmlFor={id} label={label} required={required} />}

        <SingleDatePicker
          numberOfMonths={numberOfMonths}
          id={id}
          verticalSpacing={1}
          {...props}
          ref={ref}
          displayFormat={displayFormat}
          focused={pickerFocused}
          readOnly={readOnly}
          hideKeyboardShortcutsPanel
          placeholder={
            placeholder ? translate(placeholder) : translate('label.selectDate')
          }
          isOutsideRange={(day) => isOutsideRange(day, min, max)}
          onDateChange={(moment) =>
            onChange(moment ? format(moment, dateFormat) : null)
          }
          onFocusChange={({ focused }) => setPickerFocused(focused)}
          showClearDate={isClearable}
          date={value ? newDate(value) : null}
          navPrev={
            <LegacyDiv className={s.arrowLeft}>
              <ArrowLeft />
            </LegacyDiv>
          }
          navNext={
            <LegacyDiv className={s.arrowRight}>
              <ArrowRight />
            </LegacyDiv>
          }
          customInputIcon={<CalendarIcon />}
          renderMonthElement={({
            month: currentMonth,
            onMonthSelect,
            onYearSelect,
          }) => {
            const monthIndex = month(currentMonth);

            return (
              <LegacyDiv className={s.monthAndYearWrap}>
                <LegacyDiv className={s.selectWrap}>
                  <Select
                    isClearable={false}
                    value={monthIndex}
                    maxMenuHeight={220}
                    options={monthOptions}
                    onChange={(selectedMonth) => {
                      onMonthSelect(currentMonth, String(selectedMonth));
                    }}
                  />
                </LegacyDiv>

                <LegacyDiv className={s.selectWrap}>
                  <Select
                    isClearable={false}
                    value={year(currentMonth)}
                    maxMenuHeight={220}
                    options={yearOptions}
                    onChange={(selectedYear) =>
                      onYearSelect(currentMonth, String(selectedYear))
                    }
                  />
                </LegacyDiv>
              </LegacyDiv>
            );
          }}
          renderDayContents={renderDayWithWeekNumber}
        />

        {error && <span className={s.errorMsg}>{translate(error)}</span>}

        {info && <span className={s.infoMsg}>{translate(info)}</span>}
      </LegacyDiv>
    );
  },
);

DatePicker.displayName = 'DatePicker';
DatePicker.propTypes = {
  label: t.string,
  required: t.oneOfType([t.bool, t.string]),
  error: t.string,
  info: t.string,
  placeholder: t.string,
};

const FormDatePicker = withFormControl(DatePicker);

type DatePickerWithFormControlProps = React.ComponentProps<
  typeof FormDatePicker
>;

FormDatePicker.propTypes = {
  name: t.string.isRequired,
};
// export { DatePicker, FormDatePicker } from './DatePickerOriginal';

const DatePickerWrapper = forwardRef((props: DatePickerProps, ref: any) => {
  if (newDatePickerFeatureToggle) {
    return <DatePickerNew {...props} ref={ref} />;
  }
  return <DatePicker {...props} ref={ref} />;
});

const FormDatePickerWrapper = (
  props:
    | DatePickerWithFormControlProps
    | ReactDayPickerSingleWithFormControlProps,
) => {
  if (newDatePickerFeatureToggle) {
    return (
      <FormDatePickerNew
        {...(props as ReactDayPickerSingleWithFormControlProps)}
      />
    );
  }
  return <FormDatePicker {...(props as DatePickerWithFormControlProps)} />;
};

export {
  DatePickerWrapper as DatePicker,
  FormDatePickerWrapper as FormDatePicker,
};
